var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _vm._m(0),
        _c("img", {
          staticClass: "mx-auto mb-4 max-w-full",
          attrs: {
            src: require("@/assets/images/pages/404.png"),
            alt: "graphic-404",
          },
        }),
        _c(
          "h1",
          { staticClass: "sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color" },
          [_vm._v(_vm._s(_vm.$t("404-pagina-nao-encontrada")))]
        ),
        _c(
          "p",
          { staticClass: "sm:mx-0 mx-4 mb-4 sm:mb-8 d-theme-text-inverse" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("a-pagina-nao-foi-encontrada-entre-em-con")) +
                " "
            ),
          ]
        ),
        _c("vs-button", { attrs: { size: "large" }, on: { click: _vm.back } }, [
          _vm._v(_vm._s(_vm.$t("common.back"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex justify-items-center mb-2 pt-2" }, [
      _c("img", {
        staticClass: "mx-auto",
        staticStyle: { "max-width": "300px" },
        attrs: { src: require("@/assets/images/logo/logo.png"), alt: "logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }